<template>
    <div class="py-4 container my-4 content-area px-4">
        <h2 class="main-heading">
            Refund Policy
        </h2>
        <p><strong>Last Updated:</strong> 22-August-2024</p>
        <ol>
            <li>
                <p>At Evolveair, we are committed to providing the highest quality educational content and services. Due
                    to the nature of our products and services, all sales are final, and we do not offer refunds for any
                    product or service purchased through our platform.</p>
            </li>
            <li>We encourage you to thoroughly review the details of each product or service before making a purchase.
                Should you have any questions or need further clarification on any offerings, please feel free to
                contact our support team at  <a href="mailto:echo@evolveair.org">echo@evolveair.org</a> before making a purchase
            </li>
            <li>
                Thank you for choosing Evolveair for your educational journey. Your satisfaction is important to us, and
                we are here to assist with any inquiries you may have.
            </li>
        </ol>

    </div>
</template>

<script>

</script>

<style scoped>
ol {
    list-style-type: none;
}
li,
p {
    margin-top: 1.2rem;
}

.content-area {
    border: 1px solid #f3f3f3;
    border-radius: 12px;
    background-color: white;

}

.main-heading {
    border-bottom: 1px solid grey;
    padding-bottom: 1rem;
    margin-bottom: 2rem;
    text-transform: capitalize;
    font-weight: 600;

}
</style>