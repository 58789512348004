<template>
  <div>
    <div class="call-to-action__content flex-column flex-sm-row gap-4 gap-sm-0">

      <div class="call-to-action__shape-01"></div>
      <div class="call-to-action__shape-02"></div>
      <div class="call-to-action__shape-03"></div>

      <div class="call-to-action__caption text-center text-sm-start">
        <h3 class="call-to-action__sub-title text-white">Let Us Help</h3>
        <h3 class="call-to-action__main-title text-white">Finding Your Right Courses</h3>
      </div>
      <div class="call-to-action__btn">
        <a href="https://evolveair.org/userpanel/register"  class="btnStarted">Get started</a>
        <!-- <router-link to="/evolveair.org/userpanel/register" class="btnStarted">Get started</router-link> -->
      </div>

    </div>
  </div>
</template>

<script>

export default {
  name: 'CtaView',
  components: { 

   },
  data() {
    return {
     
    };
  },
  computed: {
  },
  mounted() {
  },
  methods: {
  },


};

</script>

<style scoped>
.call-to-action__content {
    /* background-color: #F4F9FF; */
    padding: 30px 35px;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-image: linear-gradient( 135deg, #CE9FFC 10%, #7367F0 100%);
}

.call-to-action__shape-01 {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 1px solid #d8e0e7c4;
    position: absolute;
    top: auto !important;
    left: -50px !important;
    bottom: -60px;
}

.call-to-action__shape-02 {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 1px solid #d8e0e7c4;
    position: absolute;
    top: -75px !important;
    left: auto !important;
    right: 20px;
}

.call-to-action__shape-03 {
    width: 268px;
    height: 268px;
    border-radius: 50%;
    border: 1px solid #d8e0e7c4;
    position: absolute;
    top: auto !important;
    left: auto !important;
    right: -65px;
    bottom: -230px;
}

.btnStarted{
  border: none;
    color: #000000a6;
    transition: all 0.5s ease-in-out;
    /* border: 1px solid #ffffff; */
    color: #5271ff;
    background: #FFF;
    height: 45px;
    font-weight: 600;
    border-radius: 35px;
    padding: 14px 24px;
    text-decoration: none;
}
</style>
