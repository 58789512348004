import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutViewVue from '@/views/AboutView.vue'
import BlogViewVue from '@/views/BlogView.vue'
import ContactViewVue from '@/views/ContactView.vue'
import SkillhubView from '@/views/SkillhubView.vue'
import TechhubView from '@/views/TechhubView.vue'
import AscepView from '@/views/AscepView.vue'
import AffiliateView from '@/views/AffiliateView.vue'
import TermConditionView from '@/views/TermConditionView.vue'
import PrivacyPolicyView from '@/views/PrivacyPolicyView.vue'
import ShippingView from '@/views/ShippingView.vue'
import NotFoundView from '@/views/NotFoundView.vue'
import RefundView from '@/views/RefundView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: AboutViewVue
  },
  {
    path: '/blogs',
    name: 'blogs',
    component: BlogViewVue
  },
  {
    path: '/skill-hub',
    name: 'skill-hub',
    component: SkillhubView
  },
  {
    path: '/tech-hub',
    name: 'tech-hub',
    component: TechhubView
  },
  {
    path: '/ascep',
    name: 'ascep',
    component: AscepView
  },
  {
    path: '/affiliate',
    name: 'affiliate',
    component: AffiliateView
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactViewVue
  },
  {
    path: '/terms-conditions',
    name: 'terms-conditions',
    component: TermConditionView
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: PrivacyPolicyView
  },
  {
    path: '/delivery-policy',
    name: 'delivery-policy',
    component: ShippingView
  },
  {
    path: '/refund-policy',
    name: 'refund-policy',
    component: RefundView
  },
  {
    path: '/:404',
    name: '404',
    component: NotFoundView
  },
  // {
  //   path: '/about',  
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    console.log({to, from, savedPosition});
    return { top: 0 }
  }
})

export default router
