<template>
  <div class="techhub-area">
    <section class="hero_section position-relative comm_padding pb-5 mt-3 mt-md-0 py-5">
      <div class="bg-overlay position-absolute top-0 start-0 end-0 bottom-0"></div>
      <div class="container z-3 position-relative">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="left-content z-3 me-5">
              <div class="section-header  mb-4">
                <h2 class="fw-bold fs-1">
                  Affiliate
                  <span class="b-class-secondary text-blue">Program </span>
                </h2>
                <!-- <p class="sec-icon ms-5"><i class="fa-solid fa-gear"></i></p> -->
              </div>
              <p class=" text-center text-md-start"> We all know the feeling: scrolling through endless "get rich quick"
                posts, feeling burnt out and confused. Forget the FOMO and the fake gurus. This program is for real
                grinders. We don't promise overnight success, but we do show you how to build sustainable income online.
                Learn the skills, put in the work, and watch your bank account grow (slowly but surely, because adulting
                takes time, but hey, at least it's real). Ready to ditch the dream and get hustling for real? Let's do
                this. #SmartGrindersUnite </p>
              <div class="video-modal mb-3 d-flex gap-3">
                <div class="w-100 d-flex justify-content-center d-md-inline-block justify-content-md-left">
                  <router-link to="/" class="">
                    <GlobalButton title="Explore to evolve" />
                  </router-link>
                </div>
                <!-- <span><img src="../assets/icons/play.svg" alt="" width="45"/> Watch Video</span> -->
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="img_new mt-5">
              <img src="../assets/images/affilate//aff-1.jpg" alt="" class="w-100 rounded">
            </div>

          </div>
        </div>
      </div>
      <div class="container py-y my-5 ">
        <section id="advertisers" class="advertisers-service-sec pt-5 pb-5 mt-5">
          <div class="container">
            <div class="row">
              <div class="section-header text-center mb-4">
                <h2 class="fw-bold fs-1">
                  What makes our
                  <span class="b-class-secondary">affiliate program </span>unique
                </h2>
                <p class="sec-icon"><i class="fa-solid fa-gear"></i></p>
              </div>
            </div>
            <div class="row mt-5 mt-md-4 row-cols-1 row-cols-sm-1 row-cols-md-3 justify-content-center">
              <div class="col-sm-12 col-md-6 col-lg-4 mt-4">
                <div class="service-card">
                  <div class="icon-wrapper">
                    <i class="fa-solid fa-globe"></i>
                  </div>
                  <h3>Events Across Globe & Networking Globally</h3>
                  <p>
                    Grow your network & stay ahead of the curve at international events
                  </p>
                </div>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4 mt-4">
                <div class="service-card mtt-4">
                  <div class="icon-wrapper">
                    <i class="fa-solid fa-arrows-down-to-people"></i>
                  </div>
                  <h3 class="text-center">Events Across Globe & Networking Globally</h3>
                  <p class="text-center">
                    Connect & collaborate with affiliates worldwide.
                  </p>
                </div>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4 mt-4">
                <div class="service-card">
                  <div class="icon-wrapper">
                    <i class="fa-solid fa-wallet"></i>
                  </div>
                  <h3 class="text-center">Leaderboards & Multi-Use International Wallet</h3>
                  <p class="text-center">
                    See your progress & access funds easily with a global wallet.
                  </p>
                </div>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4 mt-4">
                <div class="service-card">
                  <div class="icon-wrapper">
                    <i class="fa-solid fa-money-check-dollar"></i>
                  </div>
                  <h3 class="text-center">“Evocard” Debit card for Affiliate Partners</h3>
                  <p class="text-center">
                    See your progress & access funds easily with a global wallet.
                  </p>
                </div>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4 mt-4">
                <div class="service-card mtt-4">
                  <div class="icon-wrapper">
                    <i class="fa-regular fa-circle-check"></i>
                  </div>
                  <h3 class="text-center">Report Generation System & Weekly Networking Sessions</h3>
                  <p class="text-center">
                    See your progress & access funds easily with a global wallet.
                  </p>
                </div>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4 mt-4">
                <div class="service-card">
                  <div class="icon-wrapper">
                    <i class="fa-solid fa-people-group"></i>
                  </div>
                  <h3 class="text-center">Lead Generation Support & International Community</h3>
                  <p class="text-center">
                    Access qualified leads & a global network for maximized earning.
                  </p>
                </div>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4 mt-4">
                <div class="service-card">
                  <div class="icon-wrapper">
                    <i class="fa-solid fa-recycle"></i>
                  </div>
                  <h3 class="text-center">Universal B2B Opportunities</h3>
                  <p class="text-center">
                    Tap into a vast network of B2B opportunities.
                  </p>
                </div>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4 mt-4">
                <div class="service-card mtt-4">
                  <div class="icon-wrapper">
                    <i class="fa-solid fa-hand-holding-dollar"></i>
                  </div>
                  <h3 class="text-center">Smart Investment Opportunities</h3>
                  <p class="text-center">
                    your earnings wisely with exclusive opportunities.
                  </p>
                </div>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4 mt-4">
                <div class="service-card">
                  <div class="icon-wrapper">
                    <i class="fa-solid fa-money-bill-transfer"></i>
                  </div>
                  <h3 class="text-center">Instant Automated <br> Disbursement</h3>
                  <p class="text-center">
                    Get paid fast & easily with instant payouts.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <!--   <div class="container py-y my-5">
       // evocard sectioon
        <div class="section-header text-center mb-4 pt-5">
          <h2 class="fw-bold fs-1">
            EvoCard</h2>
          <p class="sec-icon"><i class="fa-solid fa-gear"></i></p>
        </div>
     <div class="row m-auto justify-content-between">
          <div class="col-sm-12 col-md-12 col-lg-4">
            <div class="d-flex align-items-center flex-column">
              <img src="../assets/images/affilate/evoCard-Front.png" alt="" class="mt-4" width="100%">
              <img src="../assets/images/affilate/evoCard-Back.png" alt="" class="mt-5" width="100%">
            </div>
          </div>
          <div class=" col-sm-12 col-md-6 col-lg-4">
            <img src="../assets/images/affilate/evoPassport-Front.png" alt="" width="100%">
          </div>
          <div class=" col-sm-12 col-md-6 col-lg-4">
            <img src="../assets/images/affilate/evoPassport-Back.png" alt="" width="100%">

          </div>

        </div> 
        // certificates section
        <div class="section-header text-center mb-4 pt-5">
          <h2 class="fw-bold fs-1">
            Certificates</h2>
          <p class="sec-icon"><i class="fa-solid fa-gear"></i></p>
        </div>

        <div class="row">
          <div class="col-md-4">
            <img src="../assets/images/affilate/certificate-V3.png" alt="" width="100%" >
            <p class="fw-semibold text-center mt-3 ">Single Certificate</p>
          </div>
          <div class="col-md-4">
            <img src="../assets/images/affilate/certificate-V1.png" alt="" width="100%" >
            <p class="fw-semibold text-center mt-3 ">Combined Certificate Variation - 01</p>

          </div>
          <div class="col-md-4">
            <img src="../assets/images/affilate/certificate-V2.png" alt="" width="100%" >
            <p class="fw-semibold text-center mt-3 ">Combined Certificate Variation - 02</p>

          </div>
        </div> 
      </div> -->
    </section>

  </div>
</template>

<script>
import GlobalButton from "@/components/GlobalButton.vue";

export default {

  name: "AffiliateView",
  components: {
    GlobalButton,
  },
  data() {
    return {

    };
  },
  mounted() {
  },
  methods: {
  }
};
</script>

<style scoped></style>