<template>
  <div>
    <h1 class="py-5 text-center">Blogs </h1>
  </div>
</template>

<script>


export default {
  name: "BlogView",
  data() {
    return {

    };
  },

  mounted() {
   
  },
  methods: {

  },


};

</script>

<style scoped>


</style>
