<template>
    <div>
      <section class="blog section-padding">
			<div class="container">
				<div class="row gap-0 row-gap-4">
					<div class="col-xl-4 col-md-6 col-sm-6 col-12 wow fadeIn" style="visibility: visible; animation-name: fadeIn;" v-for="(item, index) in blogs" :key="index">
						<div class="blog-item h-100">
							<div class="blog-image">
								<img :src="require('../assets/images/new-home/' + item.image)" :alt="item.image">
							</div>
							
							<div class="blog-content">
								<div class="bmeta">
									<span>
										<i class="bx bx-time-five"></i> {{ item.date}}
									</span>	

									<span class="bcat">
										<a  href="#" class="text-decoration-none">{{ item.category }}</a>
									</span>
								</div>
								
								<h3><a  href="blog-details.html" class="text-decoration-none fs-5">{{ item.title }}</a></h3>
								<a  href="blog-details.html" class="bbtn text-decoration-none">Explore More</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
    </div>
</template>

<script>
export default {
    name:'BlogCard',
    components: { 
     },
    data() {
      return {

            blogs: [
              {
                image: "1.jpg",
                date: "27 Jan , 2024",
                category: "eLearning",
                title: "A Student Learning with Online Programme on Computer",
              },
              {
                image: "2.jpg",
                date: "27 Jan , 2024",
                category: "Students",
                title: "All Students and Teachers are Happy To Back to School",
              },
              {
                image: "3.jpg",
                date: "27 Jan , 2024",
                category: "Education",
                title: "Learners are studing with togather in the Class Room",
              }
            ]

      };
    },
    computed: {
      },
    mounted(){
    },
    methods:{
    },
 

    };

</script>

<style scoped>

.blog-item{
	border: 1px solid #E9EEF4;
	border-radius: 8px;	
}
.blog-image{
	position: relative;
	overflow: hidden;
	border-radius: 8px 8px 0 0;
}
.blog-image img{
	border-radius: 8px 8px 0 0;
}

.blog-image:after{
	content: '';
	width: 100%;
	height: 100%;
	background: #000;
	opacity: .3;
	position: absolute;
	left: -100%;
	top: 0;
	transition: .5s;
	transform: rotate(180deg);
	border-radius: 8px 8px 0 0;
}
.blog-item:hover .blog-image:after{
	left: 0;
	transform: rotate(0deg);
}
.blog-image img{
	width: 100%;
}
.post-image{
	margin-bottom: 25px;
}
.pb120{
	padding-bottom: 120px;
}
.blog-content{
	padding: 30px;
}
.blog-content h3{
	font-size: 24px;
	line-height: 34px;
	margin-bottom: 10px;
}
.blog-content h3 a{
	color: #222;
	transition: .5s;
}
.blog-content h3 a:hover,
.blog-content h3 a:focus{
	color: #5272ff;
}
.bmeta .bcat{
	float: right;
}
.bmeta{
	font-size: 15px;
	margin-bottom: 25px;
}
.bmeta i{
	color: #5272ff;
}
.bmeta .bcat a {
	display: inline-block;
	padding: 1px 20px;
	background: linear-gradient(180deg, #5272ffb9 0%, #5272ffdc 100%);
	color: #fff;
	border-radius: 5px 25px 5px 25px;
}
.bbtn{
	color: #444;
	font-weight: 600;
}
.blog-item{
	margin-bottom: 30px;
}
.bstyle-2 .blog-image,
.bstyle-2 .blog-image img,
.bstyle-2 .blog-image:after{
	border-radius: 8px;
}
.bstyle-2 .blog-item {
	border: 1px solid #E9EEF4;
	border-radius: 8px;
	padding: 30px;
}
.bstyle-2 .blog-content {
	padding: 33px 0 0;
}
.post-nav.pnavigation{
	margin-top: 40px;
	text-align: center;
}
.post-nav.pnavigation a {
	display: inline-block;
	background: #f1f1f1;
	padding: 5px 19px;
	font-weight: 600;
	border-radius: 4px;
	transition: .5s;
	margin: 0 15px;
}
.post-nav.pnavigation a:hover,
.post-nav.pnavigation a:focus{
	background: #5272ff;
	color: #fff;
}

</style>
