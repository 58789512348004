<template>
  <div>
    <button class="global__Button btnLogin py-2 px-4 text-decoration-none d-flex align-items-center gap-2"
      @click="handleClick" type="button">
      {{ title }}
    </button>
  </div>
</template>

<script>

  export default {
    name: 'GlobalButton',
    props: {
      title: {
        type: String,
        required: true,
      },
      clickUrl: {
        type: String,
        default: '',
      },
    },
    data() {
      return {

      };
    },
    computed: {
    },
    mounted() {
    },
    methods: {
      handleClick() {
        if (this.clickUrl) {
          window.location.href = this.clickUrl;
        }
      },
    },


  };

</script>

<style scoped>
  /* global__Button */
  .global__Button {
    padding: 12px 24px;
    transition: 0.3s ease-in-out;
    transition-property: background, color;
    position: relative;
    color: var(--white);
    border: none;
    border-radius: 35px;
    background: linear-gradient(180deg, #5272ffb9 0%, #5272ffdc 100%);
    background-size: 400%;
    background-position: 0% 0%;
    transition: all .4s;
    height: 45px;
  }

  .global__Button:hover{
    background: linear-gradient(180deg, #5272ff 0%, #0c37f7dc 100%);
  }
</style>