<template>
  <div class="techhub-area">
  <div class="bg-main-img position-relative">
    <img src="../assets/images/evolve-logo.png" alt="" class="img-fluid h-100 object-fit-contain">
  </div>
  <div class="position-absolute top-50 start-50 translate-middle coming-soon">
    <h1 class="fw-bold lh-base mb-2">WE'RE LAUNCHING SOON</h1>
    <div class="countdown-timer">
      <div class="timer-unit">
        <span class="number">{{ remainingTime.days }}</span>
        <span class="label">Days</span>
      </div>
      <div class="timer-unit">
        <span class="number">{{ remainingTime.hours }}</span>
        <span class="label">Hours</span>
      </div>
      <div class="timer-unit">
        <span class="number">{{ remainingTime.minutes }}</span>
        <span class="label">Minutes</span>
      </div>
      <div class="timer-unit">
        <span class="number">{{ remainingTime.seconds }}</span>
        <span class="label">Seconds</span>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
name: "ContactView",
data() {
  return {
    targetDate: new Date('2024-05-26'), // Set your target date here
    remainingTime: {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0
    }
  };
},
mounted() {
  this.updateTime();
  setInterval(this.updateTime, 1000);
},
methods: {
  updateTime() {
    const now = new Date();
    const difference = this.targetDate.getTime() - now.getTime();
    if (difference > 0) {
      this.remainingTime.days = Math.floor(difference / (1000 * 60 * 60 * 24));
      this.remainingTime.hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      this.remainingTime.minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      this.remainingTime.seconds = Math.floor((difference % (1000 * 60)) / 1000);
    } else {
      // If the target date has passed, set remaining time to 0
      this.remainingTime.days = 0;
      this.remainingTime.hours = 0;
      this.remainingTime.minutes = 0;
      this.remainingTime.seconds = 0;
    }
  }
}
};
</script>


<style scoped>
.bg-main-img{
height: 85vh;
}
.bg-main-img img{
filter: blur(32px);
}
/* .coming-soon h1{
color: var(--active-bg);
} */
.countdown-timer {
display: flex;
justify-content: center;
align-items: center;
}

.timer-unit {
display: flex;
flex-direction: column;
align-items: center;
margin: 0 10px;
}

.number {
font-size: 24px;
font-weight: bold;
color: var(--active-bg);
/* Change color as needed */
background-color: white;
  padding: 1rem;
  border-radius: 9px;
  height: 55px;
  width: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.label {
font-size: 14px;
  color: #000;
  font-weight: 600; /* Change color as needed */
}


</style>