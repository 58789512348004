<template>
    <div class="slider-wrap w-100"
              :key="index">
              <div class="border-0 coursesNew courseCard rounded-1 bg-white d-flex flex-column">
                  <div class="overflow-hidden p-3 position-relative">
                      <img :src="og_image" v-if="og_image"
                          class="img-fluid w-100 object-fit-cover courserImg" alt="course1"/>
                      <img src="../assets/images/new-home/courses-1.webp" v-else
                          class="img-fluid w-100 object-fit-cover courserImg" alt="course1"/>
                          <div class="category position-absolute bg-white rounded">
                            <!-- <span class="cashback">{{ CardData?.category.name }}</span> -->
                            <span class="cashback fs-7">Skillhub</span>
                          </div>
                  </div>
                  <div class="courseCard-body pb-1 px-3 d-flex flex-column gap-1">
                  
                      <a :href="vueUrl" class="courseCard-title overflow-hidden  text-decoration-none text-capitalize fw-semibold fs-6 fst-normal  w-100 d-inline-block">{{ title }}</a>
                      <div class="course-rating d-flex align-items-center">
                        <div class="d-flex ratingStar">
                          <span class="star-img">
                            <i class="fa-solid fa-star"></i>
                          </span>
                          <span class="star-img">
                            <i class="fa-solid fa-star"></i>
                          </span>
                          <span class="star-img">
                            <i class="fa-solid fa-star"></i>
                          </span>
                          <span class="star-img">
                            <i class="fa-solid fa-star"></i>
                          </span>
                          <span class="star-img">
                            <i class="fa-regular fa-star"></i>
                          </span>
                        </div>
                          <!-- <span class="course-rating-point">({{ CardData }})</span> -->
                      </div>
                      <h4 class="coursePrice price fw-semibold fst-normal fs-6 mb-0">₹ {{ price }}</h4>
                  
                  </div>
                  <div class="courseCard-bottom p-3">
                        <div class="course-price d-flex justify-content-between align-items-center">
                            <!-- <h4 class="coursePrice price fw-semibold fst-normal fs-6 mb-0">₹ {{ parseFloat(CardData?.price) }}</h4> -->
                           
                            <!-- <BuyButton class="btnFunction" /> -->
                            <a :href="vueUrl" target="_blank" class="enroll-btn d-flex justify-content-center align-items-center gap-1 w-100 text-white fw-medium position-relative border-0 text-decoration-none px-3 py-1 fs-7">Enroll Now <img src="../assets/images/new-home/angles-right.svg" alt=""></a>
                        </div>
                  </div>
              </div>
      </div>
</template>

<script>
// import BuyButton from "@/components/BuyButton.vue";
import ApiClass from '@/api/api';
export default {
    name:'CourseCard',
    props: {
      title: {
        type: String,
        required: true,
      },
      og_image: {
        type: String,
        required: false,
      },
      price: {
        type: String,
        required: true,
      },
    },
    components: { 
      // BuyButton
     },
    data() {
      return {
        vueUrl:"",
        CourseCard: [
        {
          img: "cardone.png",
          category: "Skillhub",
          title: "Various versions have evolved daf",
          rating: "15",
          price: "500",
          buy:"ArrowUpRight.svg",
        },

      ],
      };
    },
    computed: {
      },
    mounted(){
      this.vueUrl = ApiClass.vueUrl
    },
    methods:{
    },
 

    };

</script>

<style scoped>

.courseCard{
  box-shadow: 0px 4px 20px 0px rgba(238, 238, 238, 0.50);
  border-radius: 10px !important;
  border: 1px solid #efefef !important;

}
.courseCard .courserImg{
 border-radius: 6px;
  height: 180px;
}

.courseCard-title{
    overflow: hidden;
    color: #363A3D;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 28px;
}

.courseCard .star-img i {
  color: #FFC27A;
}

.courseCard .course-rating{
  gap: 12px;
}

.courseCard .ratingStar{
  gap: 6px;
}

.courseCard .courseCard-bottom{
  border-top: 1px solid #E7E9EB;
}

.courseCard .category {
   top: 23px;
    opacity: 0.8;
    left: 23px;
    padding: 6px 8px;
}


.courseCard-bottom a .btn-arrow{
    padding: 10px;
    transition: all 0.4s ease-in-out;
    background: linear-gradient(180deg, rgba(255, 194, 122, 0.12) 0%, rgba(255, 163, 55, 0.12) 100%);
    box-shadow: -4px 4px 20px 0px rgba(32, 180, 134, 0.12);
}

.courseCard-bottom a .btn-arrow:hover svg path{
    stroke: #FFF;
}

.coursePrice{
    color: #1B1D1F;
    line-height: 32px;
    letter-spacing: 0.048px;
}  

.enroll-btn{
    transition: 0.3s ease-in-out;
    transition-property: background, color;
    background: linear-gradient(180deg, #5272ffb9 0%, #5272ffdc 100%);
    height: 45px;
    border-radius: 35px;
}

</style>
