<template>
    <div class="py-4 container my-4 content-area px-4">
        <h2 class="main-heading">
            Terms and Conditions
        </h2>
        <p>Welcome to EvolveAir Education Portal (referred to as "we," "us," or "our"). By accessing or
            using our website and services, you agree to comply with and be bound by the following
            Terms and Conditions. If you do not agree with these Terms and Conditions, please do not
            use our website and services.
        </p>
        <ul>
            <li><b>Acceptance of Terms:</b> By accessing or using the EvolveAir Education Portal, you
                acknowledge that you have read, understood, and agree to be bound by these Terms
                and Conditions. These Terms and Conditions may be updated or modified by us from
                time to time without notice, and it is your responsibility to review them regularly.</li>
            <li><b>User Registration:</b> To access certain features of the website, you may be required to
                register an account. You agree to provide accurate, current, and complete
                information during the registration process and to update such information to keep it
                accurate, current, and complete.</li>
            <li><b>Payment Gateway:</b> EvolveAir Education Portal uses Razorpay as its payment gateway.
                By making any payment on our website, you agree to comply with Razorpay's terms
                and conditions. We do not store any payment information on our servers.
            </li>
            <li><b>Content and Copyright:</b> All content on the EvolveAir Education Portal, including but
                not limited to text, graphics, logos, images, audio clips, and software, is the property
                of EvolveAir or its content suppliers and is protected by international copyright laws.
                Unauthorized use of the content may violate copyright, trademark, and other laws.</li>
            <li><b>User Conduct:</b> You agree not to engage in any of the following activities:
                <ul>
                    <li>Violating any applicable laws or regulations.</li>
                    <li>Infringing on the rights of others.</li>
                    <li>Attempting to interfere with the proper functioning of the website</li>
                    <li>Engaging in any fraudulent or deceptive activities.</li>
                </ul>
            </li>

            <li><b>Privacy Policy:</b> Our Privacy Policy governs the collection, use, and disclosure of your
                personal information. By using our website and services, you consent to the practices
                outlined in our Privacy Policy.</li>
            <li><b>Disclaimer of Warranties:</b> The EvolveAir Education Portal is provided on an "as-is"
                and "as-available" basis. We make no warranties, expressed or implied, regarding the
                accuracy, reliability, or availability of the website or its content.
            </li>
            <li><b>Limitation of Liability:</b> EvolveAir, its affiliates, and their respective officers, directors,
                employees, and agents shall not be liable for any indirect, incidental, special,
                consequential, or punitive damages arising out of or in connection with your use of
                the website.</li>
            <li><b>Governing Law</b>: These Terms and Conditions are governed by and construed in
                accordance with the laws of Judicial Court without regard to its conflict of law
                principles.</li>
            <li><b>Contact Information:</b>If you have any questions or concerns regarding these Terms
                and Conditions, please contact us at <a href="">“info@evolveair.org”</a>
            </li>
        </ul>
        <p>By using the EvolveAir Education Portal, you acknowledge that you have read and
            understood these Terms and Conditions and agree to be bound by them.</p>

        <p class="text-center pt-3">Website operated by VIRTUCROP PRIVATE LIMITED</p>


    </div>
</template>

<script>

</script>

<style scoped>
li,
p {
    margin-top: 1.2rem;
}

.content-area {
    border: 1px solid #f3f3f3;
    border-radius: 12px;
    background-color: white;

}

.main-heading {
    border-bottom: 1px solid grey;
    padding-bottom: 1rem;
    margin-bottom: 2rem;
    text-transform: capitalize;
    font-weight: 600;

}
</style>