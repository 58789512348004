<template>
    <div class="py-4 container my-4 content-area px-4">
        <h2 class="main-heading">
            Payment and Delivery Policy
        </h2>
        <p><strong>Last Updated:</strong> 21-Feb-2024</p>
        <ol>
            <li><b>Payment:</b>
                <p>EvolveAir Education Portal utilizes Razorpay as a secure payment gateway to facilitate transactions for our digital products and services. Please review the following policies regarding payments:</p>
                <ol>
                    <li><b>Payment Methods:</b> We accept payments through various methods provided by Razorpay, including credit/debit cards, net banking, UPI, and other applicable options.</li>
                    <li><b>Payment Security:</b> Razorpay ensures the highest standards of security for online transactions. Your payment details are encrypted and securely transmitted to process the transaction.</li>
                    <li><b>Confirmation Email:</b> Upon successful payment, you will receive a confirmation email with details of your transaction. Please retain this email for reference.</li>
                </ol>
            </li>
            <li><b>Digital Products/Services Delivery:</b>
                <p>As EvolveAir Education Portal primarily offers digital products and services. Instead, the delivery of our offerings is conducted electronically. Please review the following policies regarding digital product/service delivery:</p>
                <ol>
                    <li><b>Instant Access:</b> Upon successful payment, users will have instant access to the purchased digital products or services. Access details will be provided on the confirmation page and sent to the email address used during the transaction.</li>
                    <li><b>Account Registration:</b> Some digital products or services may require account registration. In such cases, you will receive instructions on how to create or access your account in the confirmation email.</li>
                    <li><b>Technical Support:</b> If you encounter any issues with accessing or utilizing our digital products/services, our customer support team is available to assist. Please contact us at <a href="mailto:info@evolveair.org">info@evolveair.org</a>.</li>
                </ol>
            </li>
        </ol>
        <p><b>Contact Information:</b> For any inquiries related to payments, or general assistance, please contact us at <a href="mailto:info@evolveair.org">info@evolveair.org</a>.</p>
    </div>
    </template>
    
    <script>
    
    </script>
    
    <style scoped>
        li, p{
            margin-top: 1.2rem;
        }
        .content-area{
            border: 1px solid #f3f3f3;
             border-radius: 12px;
            background-color: white;
    
        }
        .main-heading{
            border-bottom: 1px solid grey;
            padding-bottom: 1rem;
            margin-bottom: 2rem;
            text-transform: capitalize;
            font-weight: 600;
    
        }
    
    </style>
    