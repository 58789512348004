<template>
    <div class="py-4 container my-4 content-area px-4">
      <div class="d-flex align-item-center justify-content-between main-heading border-b">
        <h2 class="main-heading-title">
            Privacy Policy
        </h2>
        <p class="mb-0">Last updated: <span class="font-weight-600">21-FEB-2024</span></p>
      </div>

        <p class=""><b>Welcome to EvolveAir Education Portal!</b></p>
        <p>This Privacy Policy describes how EvolveAir collects, uses, and discloses your personal information when you
            use our website and services. By accessing or using our services, you consent to the practices described in
            this Privacy Policy.</p>

        <p class=""><b class="mb-1">1. Information We Collect</b></p>
        <div class="ps-3">
                <p class=""><b class="mb-1">1.1 Personal Information</b></p>
                <p>We may collect personal information such as your name, email address, phone number, and other relevant
                    details when you register for an account or use our services.</p>

                <p class=""><b>1.2 Usage Data</b></p>
                <p>We automatically collect information about how you interact with our website and services. This may include
                    your IP address, browser type, device information, and pages visited.</p>

                <p class=""><b>1.3 Cookies</b></p>
                <p>We use cookies and similar tracking technologies to enhance your experience on our website. You can control
                    cookies through your browser settings.</p>
        </div>

        <p class=""><b>2. How We Use Your Information</b></p>
        <div class="ps-3">
            <p class=""><b>2.1 Providing Services</b></p>
        <p>We use your personal information to provide you with access to our educational services, process payments,
            and communicate with you about your account.</p>

        <p class=""><b>2.2 Improving Services</b></p>
        <p>We analyze usage data to improve our services, troubleshoot issues, and enhance the overall user experience.
        </p>

        <p class=""><b>2.3 Communication</b></p>
        <p>We may send you updates, newsletters, and promotional materials based on your communication preferences. You
            can opt-out of these communications at any time.</p>
        </div>
        

        <p class=""><b>3. Sharing Your Information</b></p>
        <div class="ps-3">
            <p class=""><b>3.1 Service Providers</b></p>
            <p>We may share your information with third-party service providers who assist us in delivering and improving
                our services.</p>

            <p class=""><b>3.2 Legal Compliance</b></p>
            <p>We may disclose your information if required by law or in response to a valid legal request.</p>
        </div>
       

        <p class=""><b>4. Data Security</b></p>
        <p>We implement industry-standard security measures to protect your personal information from unauthorized
            access, disclosure, alteration, and destruction.</p>

        <p class=""><b>5. Your Choices</b></p>
        <p>You have the right to access, correct, or delete your personal information. You can manage your communication
            preferences through your account settings.</p>

        <p class=""><b>6. Children's Privacy</b></p>
        <p>Our services are not directed to individuals under the age of 13. If you become aware that a child has
            provided us with personal information, please contact us, and we will take steps to delete the information.
        </p>

        <p class=""><b>7. Changes to this Privacy Policy</b></p>
        <p>We may update this Privacy Policy periodically. We will notify you of significant changes through the email
            address associated with your account.</p>

        <p class=""><b>8. Contact Us</b></p>
        <p>If you have any questions or concerns about this Privacy Policy, please contact us at <a
                href="mailto:info@evovleair.org">info@evovleair.org</a>.</p>

        <p>By using our services, you agree to the terms outlined in this Privacy Policy. EvolveAir Education Portal is
            committed to protecting your privacy and providing a secure online environment for educational purposes.</p>
    </div>
</template>

<script>

</script>

<style scoped>
  .font-weight-600{
    font-weight: 600;
  }
    li, p{
        margin-top: 1rem;
    }
    .content-area{
        border: 1px solid #f3f3f3;
         border-radius: 12px;
        background-color: white;

    }
    .border-b{
        border-bottom: 1px solid grey;

    }
    .main-heading-title{
        padding-bottom: 1rem;
        text-transform: capitalize;
        font-weight: 600;
    }
    p{
        margin-bottom: 0 !important;
    }
</style>