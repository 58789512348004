<template>
  <div>
    <div class="main-page overflow-hidden  w-100">
      <div class="page-not w-100 d-flex align-items-center justify-content-center">
        <img src="../assets/images/Hero.svg" alt=""  width="800">
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: "AboutView",
  components: {

  },
  data() {
    return {
       
       
    };
  },

  mounted() {
   
  },
  methods: {

  },


};

</script>

<style scoped>
.main-page{
  background-color:#5271ff ;
  /* height: 600px; */
}

.page-not{
}
</style>